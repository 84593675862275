'use client';

import './globals.css';
import { usePathname } from 'next/navigation';
import { Analytics } from '@vercel/analytics/react';
import Footer from './footer'; // Import the Footer component
import * as Sentry from '@sentry/nextjs'; // Import Sentry for error monitoring
import { useEffect } from 'react';
export default function RootLayout({
  children
}: {
  children: React.ReactNode;
}) {
  const pathname = usePathname(); // Get the current path
  // Show footer on all pages except the login and register pages
  const showFooter = pathname !== '/' && pathname !== '/register';
  useEffect(() => {
    try {
      // Any additional logic during the mount of the layout can be monitored
      console.log('RootLayout rendered successfully.');
    } catch (error) {
      // Log errors to Sentry if any unexpected issues arise

      Sentry.captureException(error);
    }
  }, []);
  return <html lang="en" data-sentry-component="RootLayout" data-sentry-source-file="layout.tsx">
      <body className="flex min-h-screen w-full flex-col">
        <div className="flex-grow">{children}</div>
        {showFooter && <Footer />}{' '}
        {/* Conditionally render the Footer component */}
        <Analytics data-sentry-element="Analytics" data-sentry-source-file="layout.tsx" />
      </body>
    </html>;
}